import clsx from 'clsx';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import React, { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header.tsx';
import RestaurantName, {
  RestaurantNameLoader,
} from '../RestaurantName/RestaurantName.tsx';
import {
  useWidgetState,
  useRestaurantId,
  useRestaurant,
  UserAtom,
  RestaurantAtom,
  AuthAtom,
} from '../lib/state.ts';

import { isBookingReady, isPaymentReady } from '../lib/ready.ts';
import NeedToKnows from '../../../components/NeedToKnows/NeedToKnows.tsx';

import {
  ReservationDates,
  ReservationDatesLoader,
} from './ReservationDates.tsx';
import {
  ReservationTimes,
  ReservationTimesLoader,
} from './ReservationTimes.tsx';
import ReservationTables from './ReservationTables.tsx';
import 'react-phone-number-input/style.css';
import styles from './Booking.module.css';
import Config from '@/lib/Config.ts';
import { AccessTypes } from './AccessTypes.tsx';

const Booking: React.FC = () => {
  const id = useRestaurantId();
  const navigate = useNavigate();
  const [widget, updateWidget] = useWidgetState();
  const booking_ready = isBookingReady(widget);
  const user = useAtomValue(UserAtom);
  const auth = useAtomValue(AuthAtom);
  const restaurant = useAtomValue(RestaurantAtom);
  const user_ready = !!auth?.token && !!user?.phone;
  const payment_ready = user_ready && isPaymentReady(widget);

  const isRWB =
    restaurant?.type === 'event' &&
    ((restaurant?.id === 336 && Config.data.environment === 'local') ||
      (restaurant?.id === 336 && Config.data.environment === 'staging') ||
      (restaurant?.id === 554 && Config.data.environment === 'production'));

  const onNext = () => {
    updateWidget({
      payment_error: undefined,
    });
    if (widget.option && widget.availability && restaurant) {
      // update for local
      const date_restaurant = dayjs(widget.availability?.datetime_utc).tz(
        restaurant.timezone
      );

      const dateFormatted = date_restaurant
        ? date_restaurant?.format('YYYY-MM-DD')
        : null;

      updateWidget({
        confirm_body: {
          restaurant_id: id!,
          seating_id: widget.option.seating_id,
          party: widget.option.seats,
          date: dateFormatted,
          time: widget.availability.time,
        },
      });
    }

    switch (true) {
      case payment_ready:
        navigate('../confirm');
        break;
      case user_ready:
        navigate('../payment');
        break;
      default:
        // Weird place for this, but make sure we don't have some old payment method set for a different user
        updateWidget({
          payment_method: undefined,
        });
        navigate('../info');
        break;
    }
  };

  return (
    <>
      <Header title="Book a reservation" step={1} />

      <div className="content">
        <Suspense fallback={<RestaurantNameLoader />}>
          <RestaurantName />
        </Suspense>

        {isRWB ? (
          <AccessTypes />
        ) : (
          <>
            {widget?.reservation_error && (
              <div className={clsx('content', styles.header_error)}>
                {widget?.reservation_error}
              </div>
            )}

            <div className={clsx('section', styles.section)}>
              <h3>Select a date</h3>

              <Suspense fallback={<ReservationDatesLoader />}>
                <ReservationDates />
              </Suspense>
            </div>

            {!!widget.date && (
              <div className={clsx('section', styles.section)}>
                <h3>Select a time</h3>

                <Suspense fallback={<ReservationTimesLoader />}>
                  <ReservationTimes key={widget.date} />
                </Suspense>
              </div>
            )}

            {!!widget.date && !!widget.availability && (
              <div className={clsx('section', styles.section)}>
                <h3>Select a table</h3>

                <Suspense fallback={<ReservationTimesLoader />}>
                  <ReservationTables
                    key={`${widget.date}-${widget.availability.uid}`}
                  />
                </Suspense>
              </div>
            )}
          </>
        )}

        {!isRWB && (
          <div className="section">
            <button
              className="nextButton"
              onClick={onNext}
              disabled={!booking_ready}
            >
              Next
            </button>
          </div>
        )}

        <div className={clsx('section', styles.needtoknow)}>
          <h3>Need to know</h3>

          <Suspense fallback={<Loading />}>
            <NTKs />
          </Suspense>
        </div>
      </div>
    </>
  );
};

const NTKs: React.FC = () => {
  const { restaurant } = useRestaurant();

  if (!restaurant) {
    return <></>;
  }

  return <NeedToKnows need_to_knows={restaurant.need_to_knows} />;
};

const Loading: React.FC = () => {
  return <div>Wait...</div>;
};

export default Booking;
